.main-body {
  margin-top: 25px;
  margin-left: 100px;
  margin-right: 15px;
  margin-bottom: 80px;
}
.common-body {
  background: #fdfeff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #00788c0d;
  border-radius: 15px;
  opacity: 1;
  position: relative;
  padding: 20px !important;
}
.vehicle-details,
.availability {
  .check-time {
    position: absolute;
    top: 15px;
    right: 17px;
    color: #66739b;
  }
  .calendear {
    position: relative;
    background: #4f9fe61a;
    height: 38px;
    top: 53px;
    border-radius: 7px;
    width: 38px;
    text-align: center;
    vertical-align: middle;
    img {
      vertical-align: middle;
      height: 21px;
      position: relative;
      top: 6px;
      right: 2px;
    }
  }
  h5 {
    color: #33778e;
  }
  hr {
    height: 1px;
    color: #4f9fe6;
  }
  label {
    color: #66739b;
    margin-bottom: 5px;
  }
}
