/*=====================================
---------------------------------------
R E S P O N S I V E  - CSS  - S T A R T 
---------------------------------------
=======================================*/

/*
    FILE INFO
  ========================================
    WEBSITE NAME        - Nextop
    WORK STARTED DATE   - 05.04.2022/04:00pm
    CSS TYPE            - Only Responsive
    CSS CREATED BY      - S.Shakil Ahmed
  ========================================
*/


/*=====================================
---------------------------------------
BRAKE POINTS:-
            - max-width: 1024px
            - min-width: 768px
            - max-width: 667px            
            - max-width: 600px            
            - max-width: 568px
            - max-width: 480px
            - max-width: 375px Main Changer
            - max-width: 320px            
---------------------------------------   
=======================================*/



/*@media screen and (min-width: 1366px) {
.container {
    width: 1300px;
    max-width: 100%;
}

} this is a setting for larger screens */




@media all and (max-width: 1166px) {}

/*================================================================================
    @media all and (max-width: 1024px) START Tab
  ================================================================================*/

@media all and (max-width: 1024px) {}

/*================================================================================
    @media all and (max-width: 1024px) END Tab
  ================================================================================*/


/*================================================================================
    @media all and (max-width: 768px) START iPhone - 6
  ================================================================================*/

@media all and (max-width: 768px) {
  .reports-tab{
    height: auto !important;
  }
  .main-section-reports .reports-tab{
    width: 100% !important;
    min-width: 655px !important;
  }
  #main-tab-setting{
    .over-scroll{
      overflow-x: auto;
    }
    .nav{
      flex-direction: row !important;
    }
display: block !important;
}
  .freights-search-select-main{
    .freights-search-select{
      flex-basis: 45%;
    }
    
  }
.popup-heading{
  font-size: 16px;
}
  .uploaded_file-transporter{
    min-width: 140px !important;
  }
  .goods-details .border-block .uploading{
    padding: 20px !important;
  }

  // start Get started
  .get_started {
    .carousel-indicators {
      right: 10%;
    }

    .carousel-item {
      .banner_block {
      }
    }
  }

  // End Get started
  //LOGIN START
  .login-main-section {

    .left-section {
      height: 30vh;


      img {
        height: 50px;
      }

      .left-login-paragraph {
        text-align: center;
        font: normal normal normal 14px/24px Overpass;
        letter-spacing: 0.38px;
        color: #FFFFFF;
        opacity: 0.75;
        padding: 0;
      }

      .heading {
        font: normal normal bold 26px/46px Overpass;
        letter-spacing: 0.72px;
        color: #FFFFFF;
        opacity: 1;
        margin-top: 0px !important;

      }

    }

    .right-section {
height: 70vh;
.right-content{
  flex-basis: 90%;
}
    }
  }


  //END LOGIN

  // Start Dashboard
  .banner-dashboard {
    margin-top: auto;

    .banner-right {}

    .banner-left {
      .heading-banner {
        font-size: 20px;
      }
    }
  }

  // End Dashboard
  //Start Add Vehicles
  .main-section-AddVehicle {
    .uploading {
      padding: 20px !important;
    }
  }

  .main-section-AddDriver {
    .uploading {
      padding: 20px !important;
    }
  }

  // End Add Vehicles

  .main-body-VehicleDetails {
    .image {

      margin-top: 0px;

    }
  }

  // contact us start
  

  //contact us end
  // Live status

  .cxl-status {
    position: relative;
    top: 0;
    right: 0;
    left: unset;
    border-radius: 0;
    height: 100%;
    z-index: 1101;
  }

  .cxl-load-status {
    display: block !important;
  }

  .cxl-load-status {
    li {
      display: flex;
      padding-bottom: 30px;
    }
  }

  .cxl-status {
    width: 300px;
  }

  .cxl-status-circle {
    margin-right: 15px;
  }

  .cxl-status-details {
    position: static !important;
    display: inline-block !important;
  }

  .cxl-load-status{
   li:nth-child(1)::before {
    height: 50%;
    top: 0;
  }}

  .cxl-load-status{
   li::before {
    height: auto !important;
    width: 4px !important;
    top: -50% !important;
    left: 8px !important;
    bottom: 0 !important;
    margin: auto !important;
  }
}
  .cxl-load-status{
   li:nth-last-child(1)::before {
    height: 50%;
    top: -110%;
  }}

  .cxl-load-status::before {
    content: none;
  }

  .cxl-load-status{
   li.cx-status-ccenter {
    text-align: left;
  }}
  .cxl-load-status{
   li.cx-status-ccenter {
   .cxl-status-details {
    text-align: left;
  }
}}
  .cxl-load-status 
  li.cx-status-cright 
  .cxl-status-details {
    text-align: left;
  }

  .cxl-load-status
   li.cx-status-cright {
    text-align: left;
  }

  .cxl-load-status 
  li.cx-status-cright
   .cxl-status-details {
    text-align: left;
  }

}

/*================================================================================
    @media all and (max-width: 768px) END iPhone - 6
  ================================================================================*/


/*================================================================================
    @media all and (max-width: 667px) START iPhone - 6
  ================================================================================*/

@media all and (max-width: 667px) {}

/*================================================================================
    @media all and (max-width: 667px) END iPhone - 6
  ================================================================================*/


/*================================================================================
    @media all and (max-width: 600px) START Tab
  ================================================================================*/

@media all and (max-width: 600px) {}

/*================================================================================
    @media all and (max-width: 600px) END Tab
  ================================================================================*/


/*================================================================================
    @media all and (max-width: 568px) START iPhone - 5
  ================================================================================*/

@media all and (max-width: 576px) {
  .profile-img img{
    margin-top: -40px !important;
    height: 70px !important;
    width: 70px !important;
  }
  .main-profile-section .banner-img{
    height: 90px !important;
  }
  .responsive-card-body{
    margin-left: 0 !important;
    margin-top: 10px;
  }
.card-rsponsive-img{
  height: 40px;
}
  .rsponsive-card-data{
    flex-direction: column !important;
  }
  //Tab Link

  .tab-link{
    font-size: 12px;
  }
  // start Get started
  .get_started {
    .carousel-indicators {
      justify-content: center;

    }

    .carousel-item {
      .banner_block {}
    }
  }

  // End Get started
  // Start Dashboard
  .banner-dashboard {
    .banner-right {
      top: 0;
      right: 0;
    }

  }

  // End Dashboard

  // start Add driver
  .main-section-AddDriver {
    .cx-profile-pic {
      .camera-icon {
        
      }
    }
  }

  //End Add Driver
  .link-title-FreightsDetails {
    flex-direction: column;

    .right-link {
      margin-top: 10px;

    }

    .freight-link {
      display: block;
      margin-top: 7px;
    }
  }

  #freight-details {
    top: 140px;
    overflow: auto;
  }
}

/*================================================================================
    @media all and (max-width: 568px) END iPhone - 5
  ================================================================================*/





/*================================================================================
    @media all and (max-width: 480px) START iPhone - 3 + 4
  ================================================================================*/
@media all and (max-width: 480px) {
  .main-body-DriverDetails .name-driver{
    font-size: 20px;
  }
  .top-content .rating img{
    margin: 0;
  }

  .main-body-VehicleDetails .name-vehicle{
    font-size: 20px;
  }

  .dashboard-card .left-card-section .left-count{
    font-size: 16px;
  }
  .dashboard-card .left-card-section label{
    font-size: 10px !important;
  }
  .banner-dashboard .banner-left .get-start-btn{
    padding: 7px 22px;
  }


.get-in-touch{
  font-size: 20px;
}

  // start Get started
  .get_started {
    .carousel-indicators {}

    .carousel-item {
      .banner_block {}
    }
  }

  // End Get started
  //PROFILE START
  .main-profile-section {
    .details-profile {
      padding-left: 15px !important;
    }
  }

  // PROFILE END

}

/*================================================================================
    @media all and (max-width: 480px) END iPhone - 3 + 4
  ================================================================================*/






/*================================================================================
    @media all and (max-width: 375px) START iPhone - 6
  ================================================================================*/

@media all and (max-width: 375px) {
  .match-making-tab-body-select-transporter{
    min-width: 100% !important;
  }
}


/*================================================================================
    @media all and (max-width: 375px) END iPhone - 6
  ================================================================================*/





/*================================================================================
    @media all and (max-width: 320px) START iPhone - 3 + 4
  ================================================================================*/

@media all and (max-width: 320px) {}


/*================================================================================
    @media all and (max-width: 320px) END iPhone - 3 + 4
  ================================================================================*/

/*=====================================
---------------------------------------
R E S P O N S I V E  - CSS  -     E N D 
---------------------------------------
=======================================*/