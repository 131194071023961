.LoadDetails-main {
  margin: 20px 20px 20px 90px;
  transition: all 0.5s;

  p {
    font-size: 14px !important;
    font-weight: 600;
  }

  label {
    font-size: 12px !important;
  }

  .view-detail-btn-load {
    a {
      text-decoration: none;
      font-size: 14px;
      letter-spacing: 0.34px;
      color: #4fbfa4;
      opacity: 1;
    }
  }

  .name-driver {
    font-size: 16px;
    letter-spacing: 0.48px;
    color: #191a1a;
    opacity: 1;

    .rating {
      span {
        font-size: 14px;
        letter-spacing: 0.28px;
        color: #191a1a;
        opacity: 1;
      }
    }
  }

  .doc-img {
    display: flex;

    .document-img {
      width: 100% !important;
    }

    a {
      img {
        margin-left: 8px;
      }
    }
  }
}

.popup-load-details {
  .card-heading-ref-no {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.26px;
    color: #38847c;
  }

  .modal-content {
    padding: 20px;
    background: #f4fcfc 0% 0% no-repeat padding-box;
    border-radius: 15px;
    opacity: 1;
    max-width: 400px;
  }

  table {
    min-width: auto;

    td {
      padding: 10px;
    }
  }
}

.offerLoadsMainLists {
    #accept{
color: green;
margin-right: 7px;
font-weight: 600;
    }
    #reject{
color: red;
font-weight: 600;

    }
    .regiBTN{
        text-decoration: none;
        background: #4fbfa419 0% 0% no-repeat padding-box;
        border-radius: 5px; 
        padding: 5px 10px;
    }
  .actionBtn-directOrder {
    text-decoration: none;
    background: #4fbfa419 0% 0% no-repeat padding-box;
    border-radius: 5px;
    height: 32px;
    width: 32px;
    display: inline-block;
position: relative;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    margin: 0 5px;
    img{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
  }
  .mainLoadTable {
    table {
      min-width: 1100px;
      thead {
      }
      tbody {
        tr {
        }
        td {
          padding: 10px 10px;
        }
        .option-dropdown .arrowvehicle {
          position: absolute;
          left: 174px;
          bottom: 27px !important;
        }
        .option-dropdown ul {
          width: 198px;
        }
      }
    }
  }
}
