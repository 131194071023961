.container-xl {
  background-color: #f0f9fc;
  // overflow-x: hidden !important;
  // overflow-y: hidden !important;
  font-family: "Overpass", sans-serif;
}

.main {
  height: 100% !important;
  background-color: #f0f9fc;
  overflow-x: hidden !important;
  margin: 20px 20px 20px 90px;
  transition: all 0.5s;
}

.details-card {
  bottom: 20px;

  .card-style {
    border: none;
    background: #FDFEFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #00000007;
    border-radius: 15px;
    opacity: 1;
  }

  .left-card {
    height: 600px;
    position: relative;
    overflow-y: hidden;

    .Auction_duration {
      text-align: left;
      font: normal normal 600 16px/25px Overpass;
      letter-spacing: 0.48px;
      color: #41518f;
      opacity: 1;
    }

   

    .blue_link {
      text-decoration: none;
      color: #4f9fe6;
    }

    .All {

      font: normal normal 600 16px/25px Overpass;
      letter-spacing: 0.48px;
      color: #4f9fe6;
      opacity: 1;
    }

    .godside {
      text-align: left;
      font: normal normal normal 16px/22px Overpass;
      letter-spacing: 0.42px;
      color: #66739b;
      opacity: 1;
    }

    .kevin {
      font: normal normal normal 14px/22px Overpass;
      letter-spacing: 0.42px;
      color: #2c3244;
      opacity: 1;
      padding-left: 20px;

    }

    #short {
      height: auto;
      padding: 5px;
      background-color: #f5f8fd;
      border-radius: 10px;
    }

    #days {
      height: auto;
      padding: 5px;
      background-color: #fdfeff;
      border-radius: 10px;
    }

    #blank {
      height: auto;
      padding: 5px;

      margin-top: 80px;
      background-color: #f5f8fd;
      border-radius: 10px;
      background-color: #4f9fe619;

      .all {
        text-align: center;
        color: #4f9fe6;
      }
    }

    #makebid {
      height: 45px;

      background-color: #f5f8fd;
      border-radius: 10px;
      background-color: #4f9fe619;
    }

    #select {
      width: 100%;
      height: 48px;
      padding: 5px;
      background-color: #f5f8fd;
      border-radius: 10px;
      background-color: #4f9fe619;

      h5 {
        color: #4f9fe6;
      }

      h5:hover {
        color: #41518f;
      }
    }

    .viewArow {
      paddign-top: 40px;
    }

    .reject {
      text-decoration: none;
      text-align: right;
      font: normal normal 600 14px/22px Overpass;
      letter-spacing: 0.42px;
      color: #ff0000;
      opacity: 1;
    }

    .accept {
      text-decoration: none;
      text-align: right;
      font: normal normal 600 14px/22px Overpass;
      letter-spacing: 0.42px;
      color: #4fbfa4;
      opacity: 1;
    }

    .bestoffer {
      letter-spacing: var(--unnamed-character-spacing-0-48);
      text-align: left;
      margin-top: 12px;
      font: normal normal 600 16px/25px Overpass;
      letter-spacing: 0.48px;
      color: #41518f;
      opacity: 1;
    }
  }

  .right-card {
    height: 100%;
    overflow: hidden;
    margin-left: 100px;

    .allbids {
      text-align: left;
      font: normal normal 600 16px/25px Overpass;
      letter-spacing: 0.48px;
      color: #2c3244;
      opacity: 1;
    }

    .viewd {
      text-align: left;
      font: normal normal normal 12px/18px Overpass;
      letter-spacing: 0.36px;
      color: #4f9fe6;
      opacity: 1;
    }

    .blue_link {
      text-decoration: none;
      color: #4f9fe6;
    }

    .date-time {
      text-align: right;
    }

    .allside {
      text-align: right;
      font: normal normal normal 14px/22px Overpass;
      letter-spacing: 0.42px;
      color: #2c3244;
      opacity: 1;
    }

    span {
      color: #2c3244;
    }

    .reject {
      text-decoration: none;
      text-align: right;
      font: normal normal 600 14px/22px Overpass;
      letter-spacing: 0.42px;
      color: #ff0000;
      opacity: 1;
    }

    .accept {
      text-decoration: none;
      text-align: right;
      font: normal normal 600 14px/22px Overpass;
      letter-spacing: 0.42px;
      color: #4fbfa4;
      opacity: 1;
    }

    #accRej {
      // position: relative;
      right: 0px;
      padding-left: 150px;
      padding-right: 50px;

      a {
        padding-right: 30px;
      }
    }

    #ken {
      position: relative;

      // right: 0px;
      // padding-left: 200px;
      // padding-right: 50px;
      a {
        // padding-right: 20px;
      }
    }
  }

  #bids {
    border-radius: 10px;
    background-color: #f5f8fd;
  }

  .Auction_links {
    text-align: right;
  }
}

@media only screen and (max-width: 776px) {
  //  .details-card{
  //      width: 100%;
  //      height: 100%;
  //  }
}

@media only screen and (max-width: 441px) {
  .main {
    height: 100%;
  }
}