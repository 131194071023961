@import '../css/mixin.scss';
@import '../css/variables.scss';

.main-section-faq{
    
    margin-left: 70px;
    transition: all 0.5s ease-in-out;
}
.accordion-item{
    background: #fcfeff 0 0 no-repeat padding-box;
    border: 1px solid #fff;
    border-radius: 5px;
    box-shadow: 0 4px 16px #0091b419;
    margin-bottom: 20px;    
}
.accordion-button:not(.collapsed) {
    background-color: #fff;
    box-shadow: none;
    color: #030833;}