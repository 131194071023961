
//  **** Color Variables ***** 

$label-color: #66739B;
$bg-color1:#F5F8FD;
$bg-color2:#F0F9FC;
$title-color:#33778E;
$link-color:#4F9FE6;
$data-font-color:#2C3244;
$btn-bg-color1:#ECF5FD;
$btn-bg-color2:#4F9FE6;
$btn-font-color1:#4F9FE6;
$btn-font-color2:#FFFFFF;
$hr-color:#4f9fe6;
$card-bg-color:#FDFEFF;
$inputBox-borderColor: #D1D5D9;
$vehicles-color:#9E55F2;
$offerVehicles-color:#FF6819;
$offerFrieghts-color:#1FA300;
$frirghts-color:#0381FF;

