@import "../css//variables.scss";
@import "../css/mixin.scss";

.main-section-DriverDetails{
  transition: all 0.5s ease-in-out;
  margin: 20px 20px 20px 20px;
}
.main-body-DriverDetails {
  

  .name-driver {
    text-align: left;
    font: normal normal 600 24px;
    letter-spacing: 0.48px;
    color: #2C3244;
    opacity: 1;
  }

  .data {
    text-align: left;
    font-size: 14px ;
    letter-spacing: 0.32px;
    color: #191A1A !important;
    opacity: 1;
    font-weight: 600;
  }
  .card-heading {
    text-align: left;
    font-size:  16px ;
    letter-spacing: 0.48px;
    color: #3E5656;
font-weight: 600;
  }
}

.common-body {
  @include common-body;
  //   left vehicles details

}

// right vehicles details
.top-content {

  .icon-dropdown {
    text-align: right;

    .arrow {
      position: absolute;
      left: 135px;
      bottom: 67px;
    }

    ul {
      border-radius: 10px;
      border: none;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 4px 16px #00000012;
      transform: translate3d(-45px, 0px, 0px) !important;
    }

    button {
      color: #66739B;
    }
  }

  .rating {
    img {
      margin-right: 5px;
    }

  }

  .profile-pic {
    img {
      border-radius: 50%;
      height: 72px;
      width: 72px;
    }
  }
}

// Documents details
.documents-detail-section,
.owner-detail-section {


  

}



.insurance-copy {
  position: relative;

  .dot-icon {
    position: relative;
    margin-left: 10px;
  }
}