@import "../css//variables.scss";
@import "../css/mixin.scss";

.main-profile-section {

    label {
        color: #898E8E;
      font-size: 12px !important;
      letter-spacing: 0.48px;
      font-family: "Overpass", sans-serif;
    }
    p {
        color: #191A1A !important;
       font-size: 14px;
    }
    
    .dropdown-menu.show {
        inset: -25px 30px auto auto !important;
    }
    @include main-body;
    margin-left: 90px;

    
    .details-profile {
        padding-left: 25px;
    }

    .banner-img {
        background-image: url("../images/Dashboard/banner_bg.png");
        height: 150px;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        border-radius: 20px;
    }
}

.profile-img {
    img {
        margin-top: -50px;
        width: 100px;
        height: 100px;
        background: transparent 0% 0% no-repeat padding-box;
        border: 3px solid #FFFFFF;
        border-radius: 20px;
        opacity: 1;
    }

    .name-profile {
        text-align: left;
        font: normal normal bold 16px/25px Overpass;
        letter-spacing: 0.48px;
        color: #2C3244;
        opacity: 1;
        display: inline-block;
    }

    
}
.dot-icon {
    margin-left: 5px;
    width: auto !important;
    height: 24px;
}

.doc-img-style{
    width: 100%;
}