@import "../css//variables.scss";
@import "../css/mixin.scss";

.contract-main-section {
  margin-left: 70px;
  transition: all 0.5s ease-in-out;

  .contract-top-bar {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #00000008;
    opacity: 1;
    padding: 10px 20px;
    vertical-align: middle;
  }

  .contract-top-heading {
    text-align: left;
    font: normal normal 600 16px/25px Overpass;
    letter-spacing: 0.26px;
    color: #2c3244;
    opacity: 1;
    margin: 0;
  }

  .contract-paragraph {
    padding: 20px;
    font-size:14px ;
letter-spacing: 0.22px;
color: #898E8E !important;
  }

  .contract-rates {
    padding: 20px;
  }
}

table {
  width: 100%;
  // min-width: 1400px;
  border-collapse: separate;
  border-spacing: 0 15px;
  overflow-x: auto;
  text-align: left;

  .t-row {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #00000007;
    border-radius: 5px;
    opacity: 1;
  }
  td {
    padding: 0 10px;
    font-size:  14px;
    letter-spacing: 0.22px;
    color: #2c3244;
    opacity: 1;
  }
  th{
    padding-left: 10px;
  }
}

.shipper-rate-heading{
  font-size:   14px;
  font-weight: 600;
  letter-spacing: 0.34px;
  color: #38847C;
  text-transform: uppercase;
}
