@import "../css//variables.scss";
@import "../css/mixin.scss";


.main-body-Notification{
    @include main-body;
    margin-left: 90px
}
.notification-block{

    .option-dropdown ul{
        padding: 5px
    }

    .orderConArow {

        bottom: -14px;
    }

    .dropdown-menu.show {      
        inset: -36px 17px auto auto !important;     
    }
@include common-body;
border-radius: 5px;
padding: 10px 20px;
.notifications{
    letter-spacing: 0.48px;
    color: #191A1A;
font-size: 16px;
}
.option-menu{
    height: 24px;
    width: 20px;
    margin-left: 10px;
}
}