.offer-vehicle-btn {
    text-decoration: none;
    color: #FFFFFF;
    border: none;
    background-color: #4FBFA4;
    border-radius: 5px;
    padding: 7px 15px;
    margin-right: 20px;
}

.SelectTransporter-main {
    margin: 20px 20px 20px 90px;
    transition: all 0.5s;
}

.modal-content {
    padding: 1rem
}