@import "../css//variables.scss";
@import "../css/mixin.scss";

body {
  background-color: #f0f9fc;
}

.main-section-AddVehicle {
  @include main-body;
  margin-left: 90px;
}

.main-section-AddVehicle {
  h5 {
    color: #33778E;
  }

  label {
    color: #66739B;
  }

  

  .main-body-AddVehicle {

    .drag-drop {
      color: #B2BAD3;
      font-size: 14px;
    }

  }

  #heading-addVehicles {
    text-align: left;
    font: normal normal bold 18px Overpass;
    letter-spacing: 0.86px;
    color: #3E5656 !important;
    text-transform: uppercase;
    opacity: 1;
  }

  //   height: 100vh;
  .progress-block {
    height: auto;
    background-color: #D0EBEA;
    border-radius: 15px;

   

    .progress {
      padding: 2px;
      background-color: #f8faff;

      .progress-bar {
        background-color: #4FBFA4;
      }
    }
  }

  

  .main-vehicle-info {

    @include common-body;

    .border-block {
      border: 1px dashed #D3DBDB;
      border-radius: 10px;
      opacity: 1;

      .uploading {
        padding: 70px;
        text-align: center;

        a{
          color: #4FBFA4 !important;
        }
      }
    }

    .item_list {
      position: relative;
      margin-right: 15px;
    }

    .close_item {
      position: absolute;
      left: 40px;
      z-index: 220;
      top: -3px;

    }
  }

}

.fix-bottom-bar {

  height: 60px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px -4px 16px #0076EC1A;
opacity: 1;

  .cancel-btn {
    text-decoration: none;
    color: #4FBFA4;
    background-color:  #4FBFA41A;
    border-radius: 5px;
    padding: 7px 15px;
    margin-right: 10px;
    border: none;
  }

  .continue-btn {
    text-decoration: none;
    color: #FFFFFF;
    border: none;
    background-color: #4FBFA4;
    border-radius: 5px;
    padding: 7px 15px;
    margin-right: 20px;
  }
}

.owner-details {
  margin-bottom: 80px;
}
.progress-status {
  text-align: right;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.38px;
  
}