@import "../css/variables.scss";

// main body
@mixin main-body {
  margin: 20px 20px 20px 20px;
  font-family: "Overpass", sans-serif;
  transition: all 0.5s;

}

// common Body
@mixin common-body {
  background: #fdfeff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #00788c0d;
  border-radius: 15px;
  opacity: 1;
  font-family: "Overpass", sans-serif;
  padding: 20px;
}

// common Label
@mixin label {
  
}

// Common HR
@mixin hr {
  margin: 0 20px;
  height: 1px;
  color: $hr-color;
}

// common Input Fields
@mixin inputBox {
  border: 1px solid $inputBox-borderColor !important;
  border-radius: 7px !important;
  opacity: 1;
}
// select
@mixin selectBox {
  border: 1px solid $inputBox-borderColor !important;
  border-radius: 7px !important;
  opacity: 1;
}

// Check Box
@mixin checkBox {
  background: #daeaf8 0% 0% no-repeat padding-box;
  border-radius: 5px !important;
  opacity: 1;
}

// Card and Form title
@mixin title {
  background: #daeaf8 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  font-family: "Overpass", sans-serif;
}

// paragaraph & data
@mixin paragaraph {
  text-align: left;
  font-family: "Overpass", sans-serif;
  font: normal normal 600 14px Overpass;
  letter-spacing: 0.34px;
  color: $data-font-color;
  opacity: 1;
}

// Uploadin common style

@mixin upload {
  border: 1px dashed #dddddd;
  border-radius: 10px;
  opacity: 1;
}

// Bottom Fixed Position Button-Bar

@mixin fix-btn-Bar {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -4px 16px #0076ec1a;
  opacity: 1;
  height: 60px;
  width: 100%;
  position: fixed;
  font-family: "Overpass", sans-serif;
  bottom: 0;
  left: 0;
}
// Fix common button
@mixin btn-1 {
  background: #ecf5fd 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  font: normal normal 600 16px Barlow;
  letter-spacing: 0.38px;
  color: #4f9fe6;
}

@mixin btn-2 {
  background: #4f9fe6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  font: normal normal 600 16px Barlow;
  letter-spacing: 0.38px;
  color: #ffffff;
  opacity: 1;
}

//card-title
@mixin card-title{
  color: #2C3244;
  text-align: left;
  font: normal normal normal 16px/25px Overpass;
  letter-spacing: 0.48px;
  color: #2c3244;
  opacity: 1;
}
//card-heading 
@mixin card-heading {
  color: #33778E;
  text-align: left;
font: normal normal 600 16px/25px Overpass;
letter-spacing: 0.48px;
color: #33778E;
opacity: 1;
  
}

//descriptionlist
@mixin dd {
text-align: left;
font: normal normal normal 14px/20px Overpass;
letter-spacing: 0.42px;
color: #66739B;
opacity: 1;
  
}