@import "../css//variables.scss";
@import "../css/mixin.scss";

.main-live-map {
  .offcanvas-backdrop.show {
    opacity: 0;
  }
  .arrowvehicle {
    bottom: 53px !important;
  }

  .dropdown-menu.show {
    inset: -32px 24px auto auto !important;
  }

  transition: all 0.5s ease-in-out;
  margin-left: 70px;
}
.freight-details {
  z-index: 999;
  position: absolute;
  bottom: 20px;
  left: 90px;
  transition: all 0.5s ease-in-out;
}

.freight-name {
  text-align: left;
  font: normal normal normal 16px/22px Overpass;
  letter-spacing: 0.42px;
  color: #2c3244;
  opacity: 1;
  margin-bottom: 0;
}

.freight-link {
  background: #ecf5fd 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-decoration: none;
  padding: 9px 20px;
  margin-left: 7px;
}

.nav-pills {
  .nav-link.active {
    background-color: transparent !important;
    letter-spacing: 0.48px;
    color: #4fbfa4 !important;
    position: relative;
  }

  .nav-link.active:after {
    content: "";
    position: absolute;
    background-color: #4fbfa4;
    width: 30px;
    height: 3px;
    bottom: 1px;
    left: 15px;
    transition: all 1s ease;
  }
}

.driver-route {
  text-align: left;
  font: normal normal bold 12px/18px Overpass;
  letter-spacing: 0.36px;
  color: #4fbfa4;
  opacity: 1;
}

/*------------------------------------
            Status Style
------------------------------------*/
.cxl-status {
  position: relative;
  // top: 30px;
  // left: 30px;
  // right: 30px;
  margin: auto;
  // background: #ffffff;
  // box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .03);
  // border-radius: 15px;
  padding: 20px;
  // height: 140px;
  display: none;
}

.cxl-status.cx-active {
  display: block;
}

// .cxl-status-title {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
// }

.cxl-load-status {
  margin-top: 15px;
  display: flex;
  position: relative;
}

.cxl-load-status::before {
  // content: '';
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 4px;
  background-color: #4fbfa4;
  z-index: 990;
}

.cxl-load-status {
  li {
    list-style-type: none;

    .cxl-status-circle {
      width: 20px;
      height: 20px;
      border-radius: 50px;
      background: #ffff;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
      position: relative;
      z-index: 991;
      display: inline-block;
    }

    .inner-circle {
      width: 10px;
      height: 10px;
      border-radius: 50px;
      background: #4fbfa4;
      box-shadow: 0 0 6px 0 rgb(0 0 0 / 25%);
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 991;
      display: inline-block;
      transform: translate(-50%, -50%);
    }
  }
}

.cxl-load-status {
  li.cx-status-ccenter {
    text-align: center;
  }
}

.cxl-load-status {
  li.cx-status-cright {
    text-align: right;
  }
}

.cxl-load-status {
  li {
    position: relative;
    flex-grow: 1;
  }
}

.cxl-load-status {
  li::before {
    content: "";
    position: absolute;
    top: -8px;
    left: 0;
    bottom: 0;
    margin: auto;
    background: #ecf2fa;
    width: 100%;
    height: 4px;
    z-index: 991;
  }
}

.cxl-load-status {
  li.cxl-completed::before {
    background: #4fbfa4;
  }
}

.cxl-load-status {
  li {
    p {
      text-align: center;
      font: normal normal normal 12px/18px Overpass;
      letter-spacing: 0.36px;
      color: #66739b;
      opacity: 1;
    }
  }
}

.cxl-status-details {
  position: absolute;
}

.cxl-load-status {
  li.cx-status-cleft {
    .cxl-status-details {
      top: 25px;
      left: 0;
    }
  }
}

.cxl-load-status {
  li.cx-status-ccenter {
    .cxl-status-details {
      top: 25px;
      left: 0;
      bottom: 0;
      right: 0;
      margin: 0;
      text-align: center;
    }
  }
}

.cxl-load-status {
  li.cx-status-cright {
    .cxl-status-details {
      top: 25px;
      right: 0;
    }
  }
}
.live-img {
  z-index: 1000;

  .location-img {
    position: absolute;
    top: 250px;
    right: 187px;
  }
  .live-vehicle {
    position: absolute;
    bottom: 95px;
    right: 80px;
  }
}

.toggle_Click {
  top: 0;
  right: 0;
  z-index: 0;
  margin-top: 310px;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  cursor: pointer;

  .toggle_button {
    // -ms-transform: translateY(4px);
    // -webkit-transform: translateY(4px);
    // transform: translateY(4px);/*Move down*/
    // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);/*Reduce Shadow*/
    border-bottom: none;
    width: 30px;
    background-color: #ffffff;
    height: 90px;
    border-style: none;
    border-top-left-radius: 35px;
    border-bottom-left-radius: 35px;

    transition: all ease 0.2s;
    box-shadow: inset 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  }
  .toggle_button:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
  }
  .toggle_arrow {
  }
  .toggle_arrow:active {
    transform: rotate(180deg);
  }
}

.seekerimg {
  top: 160;
  transform: translateY(50%, -50%);
  width: 60px;
  margin-bottom: 25px;
}
.inside {
  padding: 20px;
}
.dt {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0.42px;
  color: #1f1104;
  margin-bottom: 0;
}

body {
  font-family: "Overpass", sans-serif;
}

.section {
  z-index: 1000;

  margin: 20px 20px 20px 70px;

  height: 100vh;

  background-color: #f0f9fc;
  margin: 0px;
  padding: 0px;
}

#btnre {
  background-color: #ecf5fd;
  width: 100px;
  color: #4f9fe6;
  margin: 5px;
  border-radius: 5px;
  opacity: 1;
  border: none;
}

#btnre:hover {
  color: #ffffff;
  background-color: #4f9fe6;
}

#bids {
  background-color: #ecf5fd;
  color: #4f9fe6;
  margin: 5px;
  border-radius: 5px;
  opacity: 1;
}

#bids:hover {
  color: #ffffff;
  background-color: #4f9fe6;
}

#approval {
  color: #4f9fe6;
  margin-top: 15px;
  padding-bottom: 10px;
}

#approval:hover {
  color: #33778e;
}

.viewdetails {
  position: absolute;
  margin-left: 80px;
  width: 140px;
  height: 80px;
  background-color: #ffffff;
  border-radius: 15px;
  justify-content: center;
  padding-top: 10px;

  padding-right: 10px;
  box-shadow: 0px 4px 16px #00000012;

  span {
    color: #66739b;
  }

  .aLink:hover {
    background-color: #f5f8fd;
    border-radius: 5px;
  }
}

.imgarrow {
  position: absolute;
  margin-left: 200px;
  margin-bottom: 10px;
  width: 40px;
}

.freights {
  position: absolute;
  margin-left: 30px;
  width: 190px;
  height: 240px;
  background-color: #ffffff;
  border-radius: 15px;
  justify-content: center;
  padding: 10px;

  // padding-right: 10px;
  box-shadow: 0px 4px 16px #00000012;

  .option {
    padding: 10px;
    padding-left: 0px;
    border-radius: 7px;
    text-align: left;
  }
}

dd {
  text-align: left;
  font: normal normal normal 14px/20px Overpass;
  letter-spacing: 0.42px;
  color: #66739b;
  opacity: 1;
}

.imgarrow {
  position: absolute;
  margin-left: 200px;
  margin-bottom: 10px;
  width: 40px;
}

.ongoing-dropdown {
  text-align: right;
  // margin-right: 130px;

  .ongoingarrow {
    position: absolute;
    left: 136px;
    bottom: 90px;
  }

  .dropdown-menu.show {
    margin-left: -118px !important;
  }

  ul {
    // margin-right: 100px;
    border-radius: 10px;
    border: none;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #00000012;
    transform: translate3d(-45px, 0px, 0px) !important;
  }

  button {
    color: #66739b;
    margin-right: 30px;
  }
}

.my-free {
  text-align: left;
  font: normal normal normal 16px/23px Overpass;
  letter-spacing: 0.48px;
  color: #2c3244;
  opacity: 1;
}

.requestarow {
  position: absolute;
  left: 136px;
  bottom: 36px;
}

.OfferVehicle {
  justify-content: right;
  text-align: right;
  text-align: left;
}

.bydefult {
  font: normal normal normal 14px/22px Overpass;
  letter-spacing: 0.34px;
  color: #66739b;
  opacity: 1;
  width: 100%;
  height: 100%;
  border: none;
  background-color: #ffffff;
  box-shadow: 0px 4px 16px #00000008;
  border-radius: 7px;
}

.freights-card-status {
  text-align: left;

  button {
    border: none;
    background-color: transparent;
    font-size: 14px;
    letter-spacing: 0.34px;
    color: #d54f35;
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .modal-content {
    width: 95% !important;
  }
}

.ongoing-freights-side {
  width: 349px;
  min-width: auto;

  .View-Details-link {
    font-size: 12px;
    font-weight: bold;

    letter-spacing: 0.29px;
    color: #4fbfa4;
  }

  .inside {
    div {
      &:hover {
        background-color: #eaf5f3;
        transition: all 0.3s;
      }
    }
  }
}

.main-side-freight-menu {
  position: fixed;
  top: 60px;
  right: 0;
  background-color: #ffff;
  height: 100vh;
  // width: 350px;
  min-width: auto;
  z-index: 950;
  transition: all 0.5s;

  button {
    background-color: #ffff;
    position: absolute;
    border: none;
    border-radius: 20px 0 0 20px;
    left: -18px;
    padding: 4vh 0px;
    top: 44vh;
  }
}

.active-side-menu {
  transition: all 0.5s;
  position: fixed;
  top: 60px;
  right: -350px;
  background-color: #ffff;
  height: 100vh;
  width: 350px;
  min-width: auto;
  z-index: 950;

  button {
    background-color: #ffff;
    position: absolute;
    border: none;
    border-radius: 20px 0 0 20px;
    left: -18px;
    padding: 4vh 0px;
    top: 44vh;
  }
}

.FreightsDetails-live-menu {
  .main-body-live {
    width: 350px;
    min-width: auto;
    top: 60px;
    overflow-y: auto;

    .ontitle {
      font-size: 14px;
      letter-spacing: 0.34px;
      color: #1f1104;
      font-weight: 600;
      margin-bottom: 0;
    }

    .offcanvas-header {
      background: #4fbfa41a 0% 0% no-repeat padding-box;
    }

    .topimg-live {
      height: 42px;
      width: 42px;
      border-radius: 50%;
    }

    label {
      font-size: 12px !important;
      letter-spacing: 0.29px;
      color: #6c6864 !important;
    }
  }
}

.FreightsStatus-live-menu {
  .main-body-live {
    top: 60px;
    overflow-y: auto;
    width: 350px;
    min-width: auto;
  }
}

body {
  font-family: "Overpass", sans-serif;
}

.freights-menu-mainSection {
}

.status2 {
  right: 0px;
  position: absolute;

  box-shadow: 0px 0px 0px 1px #ffffff;
  background-color: #ffffff;
  border-radius: 14px 0px 0px 12px;

  transition: 0.3s ease-in-out;

  .ontitle {
    text-align: left;

    font: normal normal normal 16px/25px Overpass;
    letter-spacing: 0.38px;
    color: #33778e;
    opacity: 1;
  }
}

.toggle-parent {
  position: absolute;
  justify-content: center;
  right: 0px;
  z-index: 99;
  justify-content: center;
  display: flex;

  text-align: center;
  justify-content: center;

  .toggle_Click {
    position: absolute;
    z-index: 99;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    cursor: pointer;

    justify-content: center;

    width: 30px;
    left: -40px;

    text-align: center;
    justify-content: center;
    height: 100px;
    padding-right: 40px;
    background-color: #ffffff;
    cursor: pointer;

    .toggle_button {
      width: 15px;
      margin-top: 40px;
      margin-left: 15px;
    }

    .toggle_button:active {
      transform: rotateZ(-180deg);
    }
  }
}

.ChangeDrive_head {
  color: var(--unnamed-color-33778e);
  text-align: left;
  font: normal normal 600 16px/25px Overpass;
  letter-spacing: 0.48px;
  color: #33778e;
  opacity: 1;
}

.ChangeDriver {
  position: fixed;
  top: 60px;
  right: 0px;
  height: 100vh;
  // overflow-y: auto;
  background-color: #ffffff;
  border-bottom: 1px solid #edf4fc;
  z-index: 1101;
}

#all {
  color: #4d9de7;
}

.statusmenu {
  color: #2c3244;
}

.card-gods {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.34px;
  color: #1f1104;
  opacity: 1;
  margin-bottom: 0;
}

.middle_bar {
  background-color: #4fbfa41a;
  padding: 15px 20px;
}

.statusheadding {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.48px;
  color: #4fbfa4;
  margin-bottom: 0;
}

.docu {
  width: 80px;
  height: 70px;
}

.statusIMg {
  // top: 42px;
  // padding-left: 5px;
  margin-right: 10px;

  .pack2 {
    // width: 23px;
    // height: 20px;
  }
}

.tick7 {
  margin-bottom: 98px;
  // border-left: 1px solid #EDF4FC;
}

.card-title {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.38px;
  color: #1f1104;
  margin: 0;
}

.timePM {
  display: block;
  margin-top: 5px;
  text-align: left;
  font: normal normal normal 12px/18px Overpass;
  letter-spacing: 0.36px;
  color: #66739b;
  opacity: 1;
}

.viewdetail {
  display: inline-block;
  text-decoration: none;
  text-align: left;
  pointer: cursor;
  font: normal normal bold 12px/18px Overpass;
  letter-spacing: 0.29px;
  color: #4f9fe6;
  opacity: 1;
}

@media query and (max-width: 446) {
  .status {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }
}

.searchBox {
  position: relative;
  display: flex;

  align-items: center;
}

.searchText {
  border-radius: 20px;
  border: none;
  outline: none;
  height: 40px;
  transition: all 0.2s linear;
}

.searchText:focus {
}

.ic-search {
  position: absolute;

  right: 10px;
  margin-top: -10px;
  width: 20px;
  height: 20px;
}

.search-icon {
  position: relative;
  text-align: center;
  cursor: pointer;
  right: 25px;
  top: 49px;
  width: 25px;
  height: 25px;
}

body {
  font-family: "Overpass", sans-serif;
}
.section-body {
  height: 100vh;
  margin: 20px 20px 20px 70px;
}
.section-main {
  height: 100%;
  margin: 20px 20px 20px 90px;
}

// --------------------load
.aby-load-status {
  margin-top: 15px;
  display: block;
  position: relative;
}
.aby-load-status::before {
  content: "";
  position: absolute;
  top: -2px;
  left: 22px;
  bottom: 0;
  margin: auto;
  background: #edf4fc;
  width: 4px;
  height: 100%;
  z-index: 991;
}
.aby-load-status li .aby-status-circle {
  width: 16px;
  height: 16px;
  border-radius: 50px;
  background: #ffffff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 991;
  display: inline-block;
  img {
    vertical-align: top !important;
  }
}
.aby-load-status li.cx-status-ccenter {
  text-align: left;
}
.aby-load-status li.cx-status-cright {
  text-align: left;
}
.aby-load-status li {
  position: relative;
  flex-grow: 1;
  display: flex;
  padding-bottom: 20px;
}
.aby-load-status li .status-details-image {
  position: relative;
  flex-grow: 1;
  display: flex;
}
.aby-load-status li::before {
  content: "";
  position: absolute;
  top: -2px;
  left: 6px;
  bottom: 0;
  margin: auto;
  // background: #ECF2FA;
  width: 4px;
  height: 90%;
  z-index: 991;
}

.aby-load-status li.aby-completed::before {
}

.aby-load-status li p {
  font-size: 12px;
  margin-bottom: 0;
}
.aby-load-status li span {
  font-size: 12px;
}

.aby-load-status li.cx-status-ccenter .aby-status-details {
  top: 25px;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  text-align: center;
}
.aby-load-status li.cx-status-cright .aby-status-details {
  // top:25px;
  right: 0;
}

@media (max-width: 991px) {
  .aby-load-status {
    display: block;
  }
  .aby-load-status li {
    padding-bottom: 21px;
  }
  .aby-status {
    width: 300px;
  }
  .aby-status-circle {
    margin-right: 15px;
  }
  .aby-status-details {
    // position:static;
    // display:inline-block;
  }
  .aby-load-status li:nth-child(1)::before {
    height: 100%;
    display: inline;
    top: 0;
  }
  .aby-load-status li::before {
    height: 90%;
    width: 4px;
    top: -40%;
    left: 6px;
    bottom: 0;
    background-color: #edf4fc;
    margin: auto;
  }
  .aby-load-status li:nth-last-child(1)::before {
    height: 50%;
    top: -110%;
  }
  .aby-load-status::before {
    content: none;
  }
  .aby-load-status li.cx-status-ccenter {
    text-align: left;
  }
  .aby-load-status li.cx-status-ccenter .aby-status-details {
    text-align: left;
  }
  .aby-load-status li.cx-status-cright .aby-status-details {
    text-align: left;
  }
  .aby-load-status li.cx-status-cright {
    text-align: left;
  }
  .aby-load-status li.cx-status-cright .aby-status-details {
    text-align: left;
  }
}
