.rating-body {
    background: #FCFEFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #0091B419;
    border-radius: 15px;
    opacity: 1;
    padding: 20px;
    max-width: 400px;

    margin: 20px;
    bottom: 50%;
    padding: 20px;
    position: relative;
    left: 50%;
    transform: translate(-50%);

}

.name-rating {
    font: normal normal 600 16px/25px Overpass;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.img-rating {
    img {
        height: 100px;
        width: 100px;
        background: transparent 0% 0% no-repeat padding-box;
        border: 3px solid #0D7F92;
        border-radius: 50%;
        opacity: 1;
    }
}

.user-name {
    font: normal normal 600 16px/25px Overpass;
    letter-spacing: 0px;
    color: #09161AF2;
    opacity: 1;
}

.user-location {
    font: normal normal normal 12px/18px Overpass;
    letter-spacing: 0px;
    color: #6C787CF2;
    opacity: 1;
}
.rating-star{
    img{
        height: 32px;
margin-right: 10px;
    }
}