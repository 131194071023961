

.main-settings{
    .over-scroll{
        flex-basis: 30% ;
    }
    position: relative;
    transition: all 0.5s;
    margin: 0 0 0 70px;
    overflow-x: hidden;
}

























.general-setting {
  padding-top: 20px;
}

.general-setting-main {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
        letter-spacing: 0.38px;
        color: #191A1A !important;
        font-size: 16px !important;
    }

    .switch-btn {


        .form-check-input {
            border-radius: 12px !important;
            width: 48px;
            height: 24px;
        }
    }
}

.users-setting {

    .setting-user-status {
        text-decoration: none;
        letter-spacing: 0.22px;
        color: #4FBFA4;
        font-size: 14px;
        font-weight: bold;
    }

    .Sub-Users-setting {
        padding: 20px;

        .option-dropdown .arrowvehicle {
            position: absolute;
            left: 135px;
            bottom: 21px;
        }

        .option-dropdown {
            ul {
                height: auto;
            }
        }

        .btn-setting {
            text-align: right;

            button {
                letter-spacing: 0.38px;
                color: #FFFFFF;
                font-size: 16px;
                font-weight: 600;
                border: none;
                padding: 8px 20px;
                background: #4FBFA4 0% 0% no-repeat padding-box;
                border-radius: 5px;
            }
        }
    }

    .nav-pills .nav-link.active:after {
        content: "";
        position: absolute;
        background-color: #4FBFA4;
        width: 30px;
        height: 3px;
        bottom: 1px;
        left: 20px;
        transition: all 1s ease;
    }

    .nav-pills .nav-link {
        border: none;
        letter-spacing: 0.42px;
        color: #B9BEBE;
        font-size: 14px;
        font-weight: bold;
        padding: 20px 20px 5px 20px;
    }

    .nav-pills .nav-link.active {
        background-color: transparent;
    }

    ul {
        background-color: #ffff;
        height: 48px;
    }
}

