.match-making{
margin: 20px 20px 20px 90px;
transition: all 0.5s;

.nav-pills .nav-link.active {
    background-color: #EEF9F6;
    letter-spacing: 0.48px;
    position: relative;
}
.nav-pills .nav-link.active:after {
    display: none;
    position: absolute;
    background-color: #56b3f4;
    width: 30px;
    height: 3px;
    bottom: 1px;
    left: 15px;
    transition: all 1s ease;
}
.data-match-making{
    text-align: left;
font-size:  14px;
font-weight: 600;
letter-spacing: 0.34px;
color: #191A1A;
}
hr{
    margin: 0 20px;
}
}
.match-making-tab-body-select-transporter{
    background: #FDFEFF 0% 0% no-repeat padding-box;
box-shadow: 0px 4px 30px #00000008;
border-radius: 15px;
opacity: 1;
height: 75vh;
width: 30%;
overflow: auto;
min-width: 300px;
margin: 10px;
p{
    font-size: 14px;
}
}

.match-making-card-heading{
    font-size:16px;
letter-spacing: 0.48px;
color: #38847C;
font-weight: 600;
}