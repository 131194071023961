// ----------------------------------------------------------------------
// SCSS APPLAY Component - TransportVehiclelist & VehicleList & DriverList
// ----------------------------------------------------------------------
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.vehicle {
  height: 100vh;
  background-color: #f0f9fc;

  
}

.searchBox {
  position: relative;

  align-items: center;
}
.searchText {
  border-radius: 20px;
  border: none;
  outline: none;
  height: 40px;
  transition: all 0.2s linear;
}

.search-ic {
  position: absolute;
  display: grid;
  place-content: center;
  margin-left: -34px;
  margin-top: -11px;
  width: 30px;
  height: 20px;
}

.PageSelect {
  //   padding-left: 31px;
  .EntPage {
    // color: #2c3244;
    margin-right: 7px;
  }
  .form-select {
    width: 100%;
  }
}

// Right Side Icons

.icons {
  width: 40px;
  height: 40px;
  // background-color: white;
  padding-top: 10px;
  text-align: center;
}
.icons:hover {
  width: 40px;
  height: 40px;
  background-color: #f0f9fc;
  padding-top: 10px;
  text-align: center;
  .add:active {
    width: 40px;
    height: 30px;
  }
}

.Vehicleimg {
  position: relative;
  top: 140px;
  left: 550px;
}

//  Media Query

@media (max-width: 991px) {
  .Vehicleimg {
    position: relative;
    top: 100px;
    left: 290px;
  }
  .VehicleTable {
    overflow-y: scroll;
  }
}
@media all and (max-width: 568px) {
  .Vehicleimg {
    top: 50px;
    left: 100px;
  }
}

//  -##---------------------------------##
//        VehicleList scss
// -##---------------------------------##
// .table-container-fluid {
// margin-top: 50px; 
//  padding: 5px;
//   table {
//     box-shadow: 15px 15px 5px 6px #f0f9fc;
//     border-collapse: collapse;
//   }
//   tr:nth-of-type(odd) {
//     color: #2c3244;
//   }
//   tr:nth-of-type(even) {
//     color: #2c3244;
//   }
//   th {
//     text-align: center;
//     font: normal normal 600 12px/17px Overpass;
//     letter-spacing: 0.19px;
//     color: #66739b;
//     opacity: 1;
    
//   }
//   td {
//     background: #ffffff 0% 0% no-repeat padding-box;
//     box-shadow: 0px 8px 16px #00000007;
//     border-radius: 5px;
//     padding: 8px;
//     text-align: center;
//     opacity: 1;
//     text-align: center;
//     font: normal normal normal 13px/22px Overpass;
//     letter-spacing: 0.22px;
//     color: #2c3244;
//     padding: 8px;
//   }

// }

.TableList {
  border: 5px;
  position: relat ive;
}
.Rowbgcolor {
  background-color: #ffffff;
  border-bottom: 3px solid #f0f9fc;
  .etc {
    color: #0770f3;
  }
}

// Table Media-Query
@media screen and (max-width: 768px),
   (min-device-width: 768px) and (max-device-width: 992px) {
//   .table-container-fluid {
//     background: transparent;

//     height: 100vh;
//   }
//   table,
//   thead,
//   tbody,
//   th,
//   td,
//   tr {
//     display: block;
//   }
//   thead tr {
//     position: absolute;
//     top: -9999px;
//     left: -9999px;
//   }
//   tr {
//     border: 1px solid #ccc;
//     margin-bottom: 5px;
//     background: #ffffff 0% 0% no-repeat padding-box;
//     box-shadow: 0px 8px 16px #00000007;
//     border-radius: 5px;
//     opacity: 1;
//   }
//   td {
//     border: none;
//     border-bottom: 1px solid #00000007;
//     position: relative;
//     padding-left: 50%;
//     text-align: right;
//   }
//   td:before {
//     position: absolute;
//     top: 6px;
//     left: 6px;
//     width: 45%;
//     padding-right: 26px;
//     white-space: nowrap;
//     font-size: 10px;
//     font-weight: 600;
//     text-align: left;
//   }
//   td:nth-of-type(1)::before {
//     content: "S.No";
//   }
//   td:nth-of-type(2)::before {
//     content: "Goods Name";
//   }
//   td:nth-of-type(3)::before {
//     content: "Company Name";
//   }
//   td:nth-of-type(4)::before {
//     content: "Company Name";
//   }
//   td:nth-of-type(5)::before {
//     content: "Quantity";
//   }
//   td:nth-of-type(6)::before {
//     content: "Estimated Arrival Time";
//   }
//   td:nth-of-type(7)::before {
//     content: "Status";
//   }



thead {
  color: #66739b;
}

.tbody {
  color: #2c3244;
  border-radius: 5px;
}
}
// Drop-Dwon click

.icon-dropdown {
  text-align: right;

  .arrowgoods {
    position: absolute;
    left: 135px;
    bottom: 5px;
  }

 

  
}

//  pagination start
.Vehiclelistpage {
  bottom: 0;
  right: 0;
  position: absolute;
  width: 250px;
  height: 40px;
  background-color: #ffffff;
  justify-content: center;
  border-radius: 8px;
  padding-left: 10px;
  text-align: center;

  .R-arow {
    border-right: 2px solid #edf4fc;
    // display: flex;
    img {
      margin-top: 5px;
      width: 12px;
      height: 15px;
    }
  }
  .L-arow {
    border-left: 2px solid #edf4fc;

    img {
      margin-top: 5px;
      width: 12px;
      height: 15px;
    }
  }
}

.Vehiclelistpage {
  display: inline-block;
}

.Vehiclelistpage a {
  color: #b2bad3;
  float: left;
  padding: 8px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
  margin: 0 4px;
}

.Vehiclelistpage a:hover {
  color: #2f6b80;
}

// -------------------------
// Driving List Edit Option
// -------------------------
.editclick {
  margin-left: 910px;
  // margin-top: 170px;
  // position: relative;
  width: 140px;
  height: 120px;
  // padding-top: -25px !important;
  // z-index: 10222;
  // transform: translate(-45px, 0px, 0px) !important;
  text-align: left;
  list-style: none;
  text-decoration: none !important;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 2px 3px 2px 5px #00000019;
  .option {
    position: relative;
  }
  .righttoggle {
    margin-left: 84px;
    margin-top: -17px;
    position: absolute;
  }
  .edit {
    text-decoration: none;
    color: #66739b;
    .edit {
      text-decoration: none;
    }
    text-decoration: none !important;
  }
}

.aLink {
  text-decoration: none;
}
.option:hover {
  background-color: #f5f8fd;
  text-decoration: none;
}

// ##------------------------------------------##
//     Media Querry [Desktop / Laptop / Modbile]
// ##-------------------------------------------##

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .vehilelist {
    width: 700px !important;
  }
  .Edit {
    margin-left: 550px;
    margin-top: 250px;
  }
  #selectRange {
    margin-top: 200px;
  }

  .pagination {
    margin-top: 200px;
    margin-left: 500px;
  }
}
@media only screen and (max-width: 992px) {
  /* For mobile phones: */
  .vehilelist {
    width: 600px !important;
  }

  .pagination {
    margin-top: 200px;
    margin-left: 500px;
  }
}
