@import '../css/mixin.scss';
@import '../css/variables.scss';

.main-body-contactUs {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    @include main-body;

    margin-left: 90px;

    .contact-us {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

}

.contactUs {
    @include common-body;
    width: auto;
    max-width: 80%;


}

.continue-btn {
    text-decoration: none;
    color: $btn-font-color2;
    background-color: #4FBFA4;
    padding: 7px 30px;
    border-radius: 20px;
    &:hover{
        color: $btn-font-color2;
    }
}

.left-paragraph {
    text-align: center;
    letter-spacing: 0.84px;
    color: #030833;
    font-size: 14px;
}
.left-email{
font-size: 14px;
font-weight: 600;
letter-spacing: 0.21px;
color: #152C37;
}

.email {
    text-align: center;
}

.left-heading {
    letter-spacing: 1.44px;
    color: #3E5656;
        font-weight: bold;
    text-align: center;
    font-size: 24px;

}

.get-in-touch{
    font-size: 20px;
    letter-spacing: 1.2px;
color: #191A1A;
}

@media(max-width:700px) {
    .main-body-contactUs {
        height: auto !important;
    }

    .contact-us {
        flex-direction: column;
    }

}