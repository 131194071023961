@import "../css//variables.scss";
@import "../css/mixin.scss";


.main-section-AddDriver {
  @include main-body;
  margin-left: 90px;

  h5 {
    color: #33778e;
  }




  .main-body-AddDriver {

    .drag-drop {
      color: #b2bad3;
    }
  }

  .progress-block {
    height: auto;
    background-color: #D0EBEA;
    border-radius: 15px;

    .heading-progress {
      text-align: left;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.86px;
      color: #3E5656;
      text-transform: uppercase;
      opacity: 1;
    }



    .progress {
      background-color: #f8faff;

      .progress-bar {
        background-color: #4FBFA4;
      }
    }
  }

  .main-vehicle-info {



    background: #FDFEFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #00000007;
    border-radius: 15px;
    opacity: 1;
    padding: 10px;

    .content {
      padding: 15px;
    }

    .border-block {
      border: 1px dashed #dddddd;
      border-radius: 15px;

      .uploading {
        padding: 70px;
        text-align: center;

        a {
          color: #4FBFA4 !important;
        }
      }
    }

    .close_item {
      position: relative;
      right: 34px;
      bottom: 19px;
    }
  }
}

.fix-bottom-bar {
  height: 60px;
  width: 100%;
  background-color: #ffff;
  position: fixed;
  bottom: 0;
  left: 0;

  .cancel-btn {
    text-decoration: none;
    color: #4FBFA4;
    background-color: #4FBFA41A;
    border-radius: 5px;
    padding: 7px 15px;
    margin-right: 10px;
  }

  .continue-btn {
    text-decoration: none;
    color: #ffffff;
    background-color: #4FBFA4;
    border-radius: 5px;
    padding: 7px 15px;
    margin-right: 20px;
  }
}

.driver-documents {
  margin-bottom: 80px;
}

.camera-icon {
  color: #4f9fe6;
  background-color: #ffffff;
  box-shadow: 0px 4px 16px #00000007;
  border-radius: 15px;
  width: 200px;
  padding: 7px;
  position: relative;
  top: 100px;
  right: 152px;
}

.cx-profile-pic {
  position: relative;
  text-align: center;
  margin: 40px 0;

  .camera-icon {
    position: absolute;
    top: 75%;
    transform: translate(-50%, 30%);
    left: 50%;
  }

  input#session-date {
    position: relative;
    overflow: hidden;
  }

  input#session-date::-webkit-calendar-picker-indicator {
    display: block;
    top: 0;
    left: 0;
    background: #0000;
    position: absolute;
    transform: scale(12)
  }
}