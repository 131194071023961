@import '../css/mixin.scss';
@import '../css/variables.scss';


.Freights-body {
    ul{
        background-color: #ffff;
        height: auto;
    }
    margin: 0 0 20px 70px;
    transition: all 0.5s ease-in-out;
    height: Auto;
    box-shadow: 0px 4px 16px #00000008;
    height: 100vh;
    .tab-link{
margin-top: 5px;
    }
}
