body {
  margin: 0;
  padding: 0;
  font-family: "Overpass", sans-serif;
}

.get_started {
  background-image: url("../images/GetStarted/bacground_image.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
}

.carousel-inner {
  width: 100vw;
  margin-left: -12px;
}

.container-fluid {}

.carousel-indicators {
  justify-content: flex-end;
  right: 13%;
}

.carousel-item {
  img {
    height: 100vh;
    width: 100%;
  }

  p {
    color: #ffffff;
    font-weight: bold;
    font-size: 20px;
  }

  .banner_block {
    position: relative;
    left: 50%;
    top: -60%;
    transform: translate(-50%, -50%);

    img {
      width: 7px;
      height: 8px;
      position: relative;
      top: 5px;
      left: 5px;
    }

    a {
      padding: 10px;
      color: #ffffff;
      font-size: 14px;
      background: #3D877F 0% 0% no-repeat padding-box;
      box-shadow: inset 0px 0px 10px #FFFFFF40;
      border-radius: 22px;
      width: 175px;
      height: 38px;
    }

    a:hover {
      background-color: #ffffff;
      color: #38847C;
      transition: 1s ease;
    }
  }
}