.main-AddVehicleSpecification {
    margin: 20px 20px 20px 90px;
    transition: all 0.5s;
}

.Add-Vehicle-Specification {
    background: #4FBFA4 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    border: none;
    padding: 5px 20px;
    font: normal normal 600 14px/22px Overpass;
    letter-spacing: 0.34px;
    color: #FFFFFF;
}

.eyes-btn {
    border: none;
}

.minus-icon {
    img {
        vertical-align: middle;
    }

    background: #4FBFA419 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    border: none;
    padding: 7px 15px;
}
.vehicleSpecification-input{
    background-color: transparent;
    width: 240px;
}
#vehicleSpecification-input{
    width: 60px;
}
