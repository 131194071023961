@import "../css/mixin.scss";

.main-body-AddGoods {
  @include main-body;
  
  margin-left: 90px !important;
  .form-check-input {
    border-radius: 5px !important;
    opacity: 1;
  }
}

.common-body {
  @include common-body;

}

.documents-section{
  margin-bottom: 80px;
  .vertical_m{
    img{
      width: 100%;
    }
  } 
}
.from-to-section,
.goods-details,
.documents-section {
  


  

  .check-time {
    position: absolute;
    top: 16px;
    right: 20px;
  }

  .uploaded_file {
    border: 1px solid #F0F6FC;
    border-radius: 8px;
    padding: 5px 11px 2px;
    margin-bottom: 15px;

    img {
      vertical-align: middle;
      width: 100% !important;
    }
  }
}

.goods-details {
  .border-block {
    border: 1px dashed #dddddd;
    border-radius: 15px;

    .uploading {
      padding: 70px;
      text-align: center;
    }
  }

  .close_item {
    position: relative;
    right: 32px;
    bottom: 3px;
  }

  .item_list {
    margin-right: -22px;
  }
}