@import "../css//variables.scss";
@import "../css/mixin.scss";

.main-section-reportTypes {
    overflow: hidden;
    margin-left: 70px;
    transition: all 0.5s ease-in-out;

    .reportTypes-section {
        padding: 15px 0;
        height: 100vh;
        background: #FDFEFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 30px #00000008;
        opacity: 1;
    }

    .top-heading {
        text-align: left;
        font: normal normal 600 18px Overpass;
        letter-spacing: 0.43px;
        color: #33778E;
        opacity: 1;
        padding: 20px;
    }

    .link-list {
        position: relative;

        a {
            display: inline-block;
            text-decoration: none;
            text-align: left;
            font: normal normal normal 16px/27px Overpass;
            letter-spacing: 0.38px;
            color: #2C3244;
            opacity: 1;
            padding: 20px;
        }
    }

    #active-link {
        background: #F5F8FD 0% 0% no-repeat padding-box;
        opacity: 1;
        color: #4F9FE6;
        width: 100%;

    }

    hr {
        margin: 0;
    }

    .arrow {
        position: absolute;
        right: -29px;
        
    }
}