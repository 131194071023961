.OrderConfirmationForTransporter-main {
    margin: 20px 20px 20px 90px;
    transition: all 0.5s;

    .modal-content {
        max-width: auto;
        background: #FDFEFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 16px #00000007;
        border-radius: 15px !important;
        opacity: 1;
    }
}

.OrderConfirmation-heading {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.38px;
    color: #3E5656;
    opacity: 1;
}

.add-btn-orderConfirmation {
    border: none;
    background: #33778E0D 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    padding: 5px 8px;
}

.btn-popup-AddRemove {}