.mainLoadTable {
  .custom-table {
    min-width: 100% !important;
    // .custom-width {
    //     width: 25%;
    // }

    // .custom-width1 {
    //     width: 10%;
    // }

    thead {
      tr {
        th {
          width: 10% !important;

          &:first-child {
            width: 3% !important;
          }

          &:last-child {
            width: 10% !important;
          }
        }

        .icon-box {
          width: 1% !important;
          // padding-top: 20px;
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #898e8e7d;

        &:last-child {
          // border-bottom: none !important;
        }

        td {
          padding: 15px 10px;
          vertical-align: top;

          &:first-child {
            // padding-left: 10px;
          }

          &:last-child {
            &:first-child {
              padding-bottom: 50px;
            }
          }
          .custom-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            img {
              // padding-bottom: 50px;
              // padding-top: 5px;
              width: 50% !important;
            }
          }
        }
      }
    }
  }

  .border-bottom {
    border-bottom: 1px solid #898e8e;
    margin-bottom: 15px;
  }

  .transporter-details {
    // width: 30%;
    .tr-img-box {
      img {
        height: 60px;
        width: 60px;
        border-radius: 50%;
      }
    }

    .tr-text-box {
      p {
        margin-bottom: 5px;
        color: #898e8e;
        font-size: 12px !important;

        span {
          color: #000;
          font-weight: 700;
          margin-left: 8px;
        }

        &:last-child {
          margin-bottom: 0 !important;
        }
      }

      .tr-name {
        p {
          color: #000;
          font-weight: 700;
          font-size: 14px !important;

          span {
            margin-left: 0 !important;
          }
        }
      }
    }
  }

  .assign-cargo {
    display: flex;
    align-items: center;
    img {
      width: 10%;
      vertical-align: middle;
    }

    p {
      padding-top: 82px;
      color: #000;
      font-weight: 700;
      font-size: 14px !important;
      &:first-child {
        padding-top: 15px;
      }
    }

    // padding: 30px 0px;
  }
}

.bottom-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;

  button {
    text-decoration: none;
    background-color: #4fbfa4;
    display: inline-block;
    padding: 10px 40px;
    border-radius: 22px;
    font: normal normal normal 16px/25px Overpass;
    letter-spacing: 0.38px;
    color: #ffffff;
    opacity: 1;
    border: none;
  }
}
