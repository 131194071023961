.body {
  font-family: "Overpass", sans-serif;
}
.main {
  height: 100vh !important;
  background-color: #f0f9fc;
  overflow-x: hidden !important;
  .details-main {
  }
}

.details-card {
  // bottom: 20px;
  overflow: hidden;
  .card-style {
    border-radius: 15px;
    border: none;
  }

  .left-card {
    height: 100%;

    overflow: hidden;
    .card-gods {
      color: var(--unnamed-color-33778e);
      text-align: left;
      font: normal normal 600 16px/25px Overpass;
      letter-spacing: 0.48px;
      color: #33778e;
      opacity: 1;
    }
    #All {
      text-align: right;
      font: normal normal 600 16px/25px Overpass;
      letter-spacing: 0.48px;
      color: #4f9fe6;
      opacity: 1;
    }
    .godside {
      text-align: left;
      font: normal normal normal 16px/22px Overpass;
      letter-spacing: 0.42px;
      color: #66739b;
      opacity: 1;
    }
    .allside {
      // text-align: right;
      font: normal normal normal 14px/22px Overpass;
      letter-spacing: 0.42px;
      color: #2c3244;
      opacity: 1;
    }

    
    .blue_link {
      text-decoration: none;
      color: #4f9fe6;
    }
    h5 {
      color: #66739b;
    }
    span {
      color: #2c3244;
    }
    #short {
      height: 48px;
      padding: 5px;
      background-color: #f5f8fd;
      border-radius: 10px;
    }
    #days {
      height: auto;
      padding: 5px;
      background-color: #4FBFA41A;
      border-radius: 10px;
    }

    #blank {
      height: 48px;
      padding: 5px;
      margin-top: 30px;
      background-color: #f5f8fd;
      border-radius: 10px;
      background-color: #4f9fe619;
      .all {
        color: #4f9fe6;
      }
    }
    

    #selectbid {
      width: 100%;
      height: 40px;
      padding: 5px;
      background-color: #f5f8fd;
      border-radius: 10px thin #0da1e1;
    }
    .card_heading {
      justify-content: space-between;
    }
  }
  .right-card {
    height: 100%;
    // width:420px;
    overflow: hidden;
    margin-left: 100px;
    h4 {
      color: #2c3244;
    }
    p {
      color: #4f9fe6;
    }

    a {
      color: #4f9fe6;
    }

    .blue_link {
      text-decoration: none;
      color: #4f9fe6;
    }
    .date-time {
      text-align: right;
    }
    h5 {
      color: #2c3244;
    }
    span {
      color: #2c3244;
    }

    #reject {
      color: #ff0000;
      text-decoration: none;
    }
    #accept {
      text-decoration: none;
      color: #4fbfa4;
    }

    #accRej {
      // position: relative;
      right: 0px;
      padding-left: 150px;
      padding-right: 50px;
      a {
        padding-right: 30px;
      }
    }

    
  }
}

#save{
position: relative;
top: 241px;
background-color: #4FBFA41A;
border-radius: 5px;
border: 0px #4FBFA4;
border-radius: 5px;

span{
  font-weight: 600;
  color: #4FBFA4;
  a{
    color: #4FBFA4;

  }
}
}
@media only screen and (max-width: 776px) {
}
@media only screen and (max-width: 441px) {
  //  .main{
  //      width: 100% !important;
  //      height: 100%;
  //  }
}
.details-card .left-card {
  height: 600px;
  position: relative;
  overflow-y: hidden;
}

.all-bids-items{
  a{
    text-decoration: none;
    color: #4FBFA4;
font-weight: 500;
  }
}