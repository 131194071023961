@import "../css//variables.scss";
@import "../css/mixin.scss";


.main-section-auctionDetails{
    @include main-body;
    margin-left: 90px;
}
.amount {
    font: normal normal 600 16px/25px Overpass;
    letter-spacing: 0.48px;
    color: #4F9FE6;
    opacity: 1;
}

.data-name {
    font: normal normal normal 16px/25px Overpass;
    letter-spacing: 0.48px;
    color: #2C3244;
    opacity: 1;
}
.vehicle-detail-section{
    margin-bottom: 80px;
}